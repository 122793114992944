<template>
    <r-e-dialog title="新增盘点单" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="500px" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="盘点单名称" prop="name">
                    <el-input v-model="formPublish.name" style="width: 300px;"/>
                </el-form-item>
            </div>
            <!--<div class="flex">
                <el-form-item label="购入日期" prop="startDate">
                    <el-date-picker v-model="purchaseDate" type="daterange" style="width: 300px;"
                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期"/>
                </el-form-item>
            </div>-->
            <div class="flex">
                <el-form-item label="所属公司" prop="companyUuid">
                    <el-input disabled v-model="formPublish.companyUuid" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="资产分类" prop="">
                    <el-select v-model="formPublish.assetsType" style="width: 300px;" multiple placeholder="请选择">
                        <el-option v-for="(item,index) in assetTypeList" :key="index" :label="item.codeName" :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="区域" prop="region">
                    <!--<el-select v-model="formPublish.region" style="width: 300px;" placeholder="请选择">
                        <el-option label="全部" :value="0"/>
                    </el-select>-->
                    <el-input v-model="formPublish.region" disabled style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="资产状态" prop="">
                    <!-- multiple 2024/01/30 张晓瑜修改为单选 -->
                    <el-select v-model="formPublish.assetState" style="width: 300px;"  placeholder="请选择">
                        <el-option v-for="(item,index) in assetsStateList" :key="index" :label="item.codeName" :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {assetsBlitemCreateCheck} from '@/api/inventory';
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";

    export default {
        name: "dialog-add-inventory",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    name: '',
                    companyUuid: '富航美寓',
                    assetsType: [],
                    region: '富航217公寓',
                    assetState: [],
                    startDate: '',
                    endDate: ''
                },
                rules: {
                    name: [{required: true, message: '请输入盘点名称', trigger: 'blur'}],
                    companyUuid: [{required: true, message: '请输入所属公司', trigger: 'blur'}],
                    assetsType:  [{required: true, message: '请选择资产类别', trigger: 'change'}],
                    region: [{required: true, message: '请输入区域', trigger: 'blur'}],
                    assetState:  [{required: true, message: '请选择资产状态', trigger: 'change'}],
                    startDate: [{required: true, message: '请选择开始时间结束时间', trigger: 'change'}],
                    endDate: [{required: true, message: '请选择开始时间结束时间', trigger: 'change'}],
                },
                assetTypeList: [],
                assetsStateList: [],
                purchaseDate:[],
            }
        },
        components: {},
        methods: {
            async openDialog() {
                let that = this;
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {name, companyUuid, assetsType, region, assetState, startDate, endDate} = that.formPublish;
                        assetState = assetState.join(",");
                        assetsType = assetsType.join(",");
                        let data = {name, companyUuid, assetsType, region, assetState,} //startDate, endDate};
                        assetsBlitemCreateCheck(data).then(res => {
                            MessageSuccess('新增盘点单成功');
                            that.clickCancel();
                            loading.close();
                        }).catch(err => {
                            MessageError('新增盘点单失败');
                            loading.close();
                        });
                    }
                })
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.$emit("handleSearch", false);
                this.dialogVisible = false;
            },
        },
        async mounted() {
            this.assetTypeList = await this.$store.dispatch('app/getDictionaryByCodeActions', 200000);
            this.assetsStateList = await this.$store.dispatch('app/getDictionaryByCodeActions', 700100);
        },
        watch:{
            purchaseDate(value){
                if(value){
                    let [startDate, endDate] = value;
                    this.formPublish.startDate=startDate;
                    this.formPublish.endDate=endDate;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-range-separator{
        padding: 0 !important;
    }
    .title {
        position: relative;
        padding-left: 10px;
        font-size: 18px;

        &::before {
            width: 5px;
            height: 100%;
            content: '';
            background-color: #F5A623;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
</style>